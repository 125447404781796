var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _c(
      "div",
      [
        _c(
          "el-tabs",
          {
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.type,
              callback: function($$v) {
                _vm.type = $$v
              },
              expression: "type"
            }
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "基础计价规则", name: "1" } },
              [
                _c("TrailerCost", {
                  ref: "tableOne",
                  attrs: { "option-list": _vm.optionList }
                })
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "线路比例和加价表", name: "2" } },
              [
                _c("VehicleTowing", {
                  ref: "tableTwo",
                  attrs: { "option-list": _vm.optionList }
                })
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }