import request from '@/utils/request'
let resquest = '/api/'

//整车基础计价规则查询接口
export function truckBaseFareRulesList(params) {
  return request({
    url: `${resquest}truck_base_fare_rules/page`,
    method: 'get',
    params,
  })
}

//整车基础计价规则失效
export function truckBaseFareRulesLoseBatch(data) {
  return request({
    url: `${resquest}truck_base_fare_rules/lose/batch`,
    method: 'put',
    data,
  })
}

//整车基础计价规则发布
export function truckBaseFareRulesPublishBatch(data) {
  return request({
    url: `${resquest}truck_base_fare_rules/publish/batch`,
    method: 'put',
    data,
  })
}

//整车基础计价规则删除
export function truckBaseFareRulesBatch(data) {
  return request({
    url: `${resquest}truck_base_fare_rules/batch`,
    method: 'delete',
    data,
  })
}

//整车基础计价规则编辑
export function truckBaseFareRulesBatchId(id, data) {
  return request({
    url: `${resquest}truck_base_fare_rules/${id}`,
    method: 'put',
    data,
  })
}
//整车基础计价规则导入url
export function truckBaseFareRulesImportUrl(data) {
  return `${resquest}truck_base_fare_rules/import`
}
//整车基础计价规则导入
export function truckBaseFareRulesImport(data) {
  return request({
    url: `${resquest}truck_base_fare_rules/import`,
    method: 'post',
    data,
  })
}

//整车线路比例和加价表查询接口
export function truckRouteRatiosList(params) {
  return request({
    url: `${resquest}truck-route-ratios/page`,
    method: 'get',
    params,
  })
}

//整车线路比例和加价表失效
export function truckRouterRatiosLoseBatch(data) {
  return request({
    url: `${resquest}truck-route-ratios/lose/batch`,
    method: 'put',
    data,
  })
}

//整车线路比例和加价表发布
export function truckRouterRatiosPublishBatch(data) {
  return request({
    url: `${resquest}truck-route-ratios/publish/batch`,
    method: 'put',
    data,
  })
}

//整车线路比例和加价表删除
export function truckRouterRatiosBatch(data) {
  return request({
    url: `${resquest}truck-route-ratios/batch`,
    method: 'delete',
    data,
  })
}


//整车线路比例和加价表编辑
export function truckRouterRatiosId(id, data) {
  return request({
    url: `${resquest}truck-route-ratios/${id}`,
    method: 'put',
    data,
  })
}

//整车线路比例和加价表导入url
export function truckRouterRatiosImportUrl(data) {
  return `${resquest}truck-route-ratios/import`
}
//整车线路比例和加价表导入
export function truckRouterRatiosImport(data) {
  return request({
    url: `${resquest}truck-route-ratios/import`,
    method: 'post',
    data,
  })
}
