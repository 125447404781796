<template>
  <div>
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="70px"
          :model="form"
          @submit.native.prevent
        >
          <el-form-item label="起运市">
            <el-select
              v-model="form.originCityId"
              clearable
              filterable
              placeholder="请选择"
              style="width: 180px"
              @change="handleBlur"
            >
              <el-option
                v-for="item in optionList.cityGetValidList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="目的市">
            <el-select
              v-model="form.destinationCityId"
              clearable
              filterable
              placeholder="请选择"
              style="width: 180px"
              @change="handleBlur"
            >
              <el-option
                v-for="item in optionList.cityGetValidList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态" label-width="50px">
            <el-select
              v-model="form.status"
              clearable
              filterable
              placeholder="请选择"
              @change="handleBlur"
            >
              <el-option
                v-for="item in optionListTwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel
        v-permissions="{ role: ['管理员', '产品主管'] }"
        :span="20"
      >
        <el-dialog
          :before-close="handleClose"
          title="导入"
          :visible.sync="dialogVisible"
          width="500px"
        >
          <span>
            <el-upload
              v-loading="loading"
              :action="importExectUrl()"
              class="upload-demo"
              drag
              :http-request="importExect"
              :show-file-list="false"
              with-credentials
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </el-upload>
          </span>

          <span slot="footer" class="dialog-footer">
            <!-- <el-button v-show="haveFail" @click="errorLink()">
              导出错误跳转查看
            </el-button> -->
            <el-button @click="dialogVisible = false">取 消</el-button>
          </span>
        </el-dialog>
        <el-button
          icon="el-icon-download"
          size="mini"
          type="primary"
          @click="baseyCode('', 'add')"
        >
          下载模板
        </el-button>
        <el-button
          icon="el-icon-upload"
          size="mini"
          type="primary"
          @click="dialogVisible = true"
        >
          导入
        </el-button>
        <el-button
          :disabled="!bulkOperations.idList.length > 0 && !isQueryAll"
          icon="el-icon-circle-check"
          size="mini"
          type="primary"
          @click="publishClick()"
        >
          发布
        </el-button>
        <el-button
          :disabled="!bulkOperations.idList.length > 0 && !isQueryAll"
          size="mini"
          type="primary"
          @click="loseClick()"
        >
          <vab-icon icon="forbid-2-line" />
          失效
        </el-button>
        <el-button
          :disabled="!bulkOperations.idList.length > 0 && !isQueryAll"
          icon="el-icon-delete"
          size="mini"
          type="danger"
          @click="deleteClick()"
        >
          删除
        </el-button>
      </vab-query-form-left-panel>
      <!-- <vab-query-form-right-panel :span="4">
        <el-checkbox v-model="isQueryAll" label="操作查询到的全部数据" />
      </vab-query-form-right-panel> -->
    </vab-query-form>

    <div style="height: calc(100vh - 330px)">
      <vxe-table
        :ref="`${tableRefName}`"
        v-loading="listLoading"
        border
        :checkbox-config="{ trigger: 'row', range: true }"
        :data="list"
        :edit-config="{
          trigger: 'manual',
          mode: 'row',
          showIcon: false,
          autoClear: false,
        }"
        :edit-rules="validRules"
        height="100%"
        :keep-source="true"
        :resizable="true"
        :row-class-name="rowStyle"
        :row-config="{ isHover: true, isCurrent: true, height: 40 }"
        :scroll-y="{ enabled: true }"
        show-overflow
        @checkbox-all="selectAllEvent"
        @checkbox-change="selectAllEvent"
        @row-click="handleRowClick"
      >
        <vxe-column align="center" fixed="left" type="checkbox" width="55" />
        <vxe-column
          align="center"
          :edit-render="{}"
          min-width="150"
          title="起运市"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.originCity }}
            </span>
          </template>
          <template #edit="{ row }">
            <vxe-select
              v-model="row.originCityId"
              filterable
              placeholder="请选择"
              @change="selectClick(row)"
            >
              <vxe-option
                v-for="item in optionList.cityGetValidList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </vxe-select>
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          min-width="150"
          title="目的市"
        >
          <template slot-scope="scope">
            <span>
              {{ scope.row.destinationCity }}
            </span>
          </template>
          <template #edit="{ row }">
            <vxe-select
              v-model="row.destinationCityId"
              filterable
              placeholder="请选择"
              @change="selectClickTwo(row)"
            >
              <vxe-option
                v-for="item in optionList.cityGetValidList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </vxe-select>
          </template>
        </vxe-column>
        <vxe-colgroup align="center" title="线路比例">
          <vxe-column
            align="center"
            :edit-render="{}"
            field="truck42Ratio"
            min-width="100"
            title="4.2"
          >
            <template #edit="{ row }">
              <vxe-input
                v-model.trim="row.truck42Ratio"
                clearable
                maxlength="12"
                placeholder="4.2"
                type="float"
              />
            </template>
          </vxe-column>
          <vxe-column
            align="center"
            :edit-render="{}"
            field="truck68Ratio"
            min-width="100"
            title="6.8"
          >
            <template #edit="{ row }">
              <vxe-input
                v-model.trim="row.truck68Ratio"
                clearable
                maxlength="12"
                placeholder="6.8"
                type="float"
              />
            </template>
          </vxe-column>
          <vxe-column
            align="center"
            :edit-render="{}"
            field="truck76Ratio"
            min-width="100"
            title="7.6"
          >
            <template #edit="{ row }">
              <vxe-input
                v-model.trim="row.truck76Ratio"
                clearable
                maxlength="12"
                placeholder="7.6"
                type="float"
              />
            </template>
          </vxe-column>
          <vxe-column
            align="center"
            :edit-render="{}"
            field="truck96Ratio"
            min-width="100"
            title="9.6"
          >
            <template #edit="{ row }">
              <vxe-input
                v-model.trim="row.truck96Ratio"
                clearable
                maxlength="12"
                placeholder="9.6"
                type="float"
              />
            </template>
          </vxe-column>
          <vxe-column
            align="center"
            :edit-render="{}"
            field="truck13Ratio"
            min-width="100"
            title="13"
          >
            <template #edit="{ row }">
              <vxe-input
                v-model.trim="row.truck13Ratio"
                clearable
                maxlength="12"
                placeholder="13"
                type="float"
              />
            </template>
          </vxe-column>
          <vxe-column
            align="center"
            :edit-render="{}"
            field="truck175Ratio"
            min-width="100"
            title="17.5"
          >
            <template #edit="{ row }">
              <vxe-input
                v-model.trim="row.truck175Ratio"
                clearable
                maxlength="12"
                placeholder="17.5"
                type="float"
              />
            </template>
          </vxe-column>
        </vxe-colgroup>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="minSurcharge"
          min-width="120"
          show-overflow-tooltip
          title="整车最低加价"
        >
          <template #edit="{ row }">
            <vxe-input
              v-model.trim="row.minSurcharge"
              clearable
              maxlength="12"
              placeholder="整车最低加价"
              type="float"
              min="0"
              max="99999.99"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="maxSurcharge"
          min-width="120"
          show-overflow-tooltip
          title="整车最高加价"
        >
          <template #edit="{ row }">
            <vxe-input
              v-model.trim="row.maxSurcharge"
              clearable
              maxlength="12"
              placeholder="整车最高加价"
              type="float"
              min="0"
              max="99999.99"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="surchargeRatio"
          min-width="120"
          show-overflow-tooltip
          title="整车加价比例"
        >
          <template slot-scope="scope">
            {{ scope.row.surchargeRatio | percentage }}
          </template>
          <template #edit="{ row }">
            <vxe-input
              v-model.trim="row.surchargeRatio"
              clearable
              maxlength="12"
              placeholder="整车加价比例"
              type="float"
              min="0"
              max="99999.99"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="poolingMinSurcharge"
          min-width="120"
          show-overflow-tooltip
          title="拼车最低加价"
        >
          <template #edit="{ row }">
            <vxe-input
              v-model.trim="row.poolingMinSurcharge"
              clearable
              maxlength="12"
              placeholder="拼车最低加价"
              type="float"
              min="0"
              max="99999.99"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="poolingMaxSurcharge"
          min-width="120"
          show-overflow-tooltip
          title="拼车最高加价"
        >
          <template #edit="{ row }">
            <vxe-input
              v-model.trim="row.poolingMaxSurcharge"
              clearable
              maxlength="12"
              placeholder="拼车最高加价"
              type="float"
              min="0"
              max="99999.99"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="poolingSurchargeRatio"
          min-width="120"
          show-overflow-tooltip
          title="拼车加价比例"
        >
          <template slot-scope="scope">
            {{ scope.row.poolingSurchargeRatio | percentage }}
          </template>
          <template #edit="{ row }">
            <vxe-input
              v-model.trim="row.poolingSurchargeRatio"
              clearable
              maxlength="12"
              placeholder="拼车加价比例"
              type="float"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="effectiveDate"
          min-width="120"
          title="生效日期"
        >
          <template #edit="{ row }">
            <el-date-picker
              v-model="row.effectiveDate"
              data-format="yyyy/MM/dd"
              format="yyyy/MM/dd"
              placeholder="请选择"
              style="width: 100%"
              type="datetime"
              value-format="yyyy/MM/dd"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="expiryDate"
          min-width="120"
          title="失效日期"
        >
          <template #edit="{ row }">
            <el-date-picker
              v-model="row.expiryDate"
              data-format="yyyy/MM/dd"
              format="yyyy/MM/dd"
              placeholder="请选择"
              style="width: 100%"
              type="datetime"
              value-format="yyyy/MM/dd"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="status"
          min-width="100"
          title="状态"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == '0'">
              <el-tag type="info">失效</el-tag>
            </span>
            <span v-if="scope.row.status == '1'">
              <el-tag>待发布</el-tag>
            </span>
            <span v-if="scope.row.status == '2'">
              <el-tag type="danger">已发布</el-tag>
            </span>
          </template>
          <template #edit="{ row }">
            <el-select v-model="row.status" filterable placeholder="请选择">
              <el-option
                v-for="item in optionListTwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="remark"
          min-width="120"
          title="备注"
        >
          <template #edit="{ row }">
            <vxe-input v-model="row.remark" clearable placeholder="备注" />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          field="createBy"
          min-width="80"
          title="创建人"
        />
        <vxe-column
          align="center"
          field="createTime"
          min-width="120"
          title="创建时间"
        />
        <vxe-column
          align="center"
          field="updateBy"
          min-width="80"
          title="修改人"
        />
        <vxe-column
          align="center"
          field="updateTime"
          min-width="120"
          title="修改时间"
        />
        <vxe-column
          v-if="hasAccess(['管理员', '产品主管'])"
          align="center"
          fixed="right"
          title="操作"
          width="160"
        >
          <template #default="{ row }">
            <template v-if="$refs[tableRefName].isActiveByRow(row)">
              <vxe-button @click="saveRowEvent(row)">保存</vxe-button>
              <vxe-button @click="cancelRowEvent(row)">取消</vxe-button>
            </template>
            <template v-else>
              <vxe-button @click="editRowEvent(row)">编辑</vxe-button>
            </template>
          </template>
        </vxe-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </vxe-table>
    </div>
    <el-pagination
      background
      :current-page="form.pageNum"
      :layout="layout"
      :page-size="form.pageSize"
      :page-sizes="[10, 50, 100, 500, 1000]"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  import { trailCarServiceExport } from '@/api/productCenter';
import {
  truckRouteRatiosList,
  truckRouterRatiosBatch,
  truckRouterRatiosId,
  truckRouterRatiosImport,
  truckRouterRatiosImportUrl,
  truckRouterRatiosLoseBatch,
  truckRouterRatiosPublishBatch,
} from '@/api/wholeVehicleProducts';
import { hasAccess } from '@/utils/hasAccess';
  export default {
    filters: {
      percentage(value) {
        return (value * 100).toFixed() + '%'
      },
    },
    props: {
      optionList: Object,
    },
    data() {
      return {
        tableRefName: 'tableSort',
        anLoading: false,
        loading: false,
        dialogVisible: false,
        fold: true,
        listLoading: false,
        height: this.$baseTableHeight(2) - 50,
        list: [],
        form: {
          sort: 'create_time',
          asc: 'false',
          pageNum: 1,
          pageSize: 100,
        },
        isQueryAll: false,
        validRules: {
          truck42Ratio: [
            { required: true, message: '请输入线路比例4.2' },
            { trigger: 'change' },
          ],
          truck68Ratio: [
            { required: true, message: '请输入线路比例6.8' },
            { trigger: 'change' },
          ],
          truck76Ratio: [
            { required: true, message: '请输入线路比例7.6' },
            { trigger: 'change' },
          ],
          truck96Ratio: [
            { required: true, message: '请输入线路比例9.6' },
            { trigger: 'change' },
          ],
          truck13Ratio: [
            { required: true, message: '请输入线路比例13' },
            { trigger: 'change' },
          ],
          truck175Ratio: [
            { required: true, message: '请输入线路比例17.5' },
            { trigger: 'change' },
          ],
          minSurcharge: [
            { required: true, message: '请输入整车最低加价' },
            { trigger: 'change' },
          ],
          maxSurcharge: [
            { required: true, message: '请输入整车最高加价' },
            { trigger: 'change' },
          ],
          surchargeRatio: [
            { required: true, message: '请输入整车加价比例' },
            { trigger: 'change' },
          ],
          poolingMinSurcharge: [
            { required: true, message: '请输入拼车最低加价' },
            { trigger: 'change' },
          ],
          poolingMaxSurcharge: [
            { required: true, message: '请输入拼车最高加价' },
            { trigger: 'change' },
          ],
          poolingSurchargeRatio: [
            { required: true, message: '请输入拼车加价比例' },
            { trigger: 'change' },
          ],
        },
        opereteAllList: [
          'commit',
          'invalid',
          'statusBack',
          'release',
          'delete',
        ],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        optionListTwo: [
          {
            id: 0,
            name: '失效',
          },
          {
            id: 1,
            name: '待发布',
          },
          {
            id: 2,
            name: '已发布',
          },
        ],
        beginTime: [],
        pickerOptions: {},
        beginTimeOne: [],
        pickerOptionsOne: {},
        bulkOperations: {
          type: '',
          idList: [],
        },
      }
    },
    mounted() {
      // this.queryList()
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    methods: {
      hasAccess,
      selectClick(row) {
        const selectedOption = this.optionList.cityGetValidList.find(
          (opt) => opt.id === row.originCityId
        )
        const rowIndex = this.list.findIndex((item) => {
          if (item.id === row.id) {
            item.originCityId = selectedOption.id
            item.originCity = selectedOption.name
          }
        })
      },
      selectClickTwo(row) {
        const selectedOption = this.optionList.cityGetValidList.find(
          (opt) => opt.id === row.destinationCityId
        )
        const rowIndex = this.list.findIndex((item) => {
          if (item.id === row.id) {
            item.destinationCityId = selectedOption.id
            item.destinationCity = selectedOption.name
          }
        })
      },
      editRowEvent(row) {
        const $table = this.$refs[this.tableRefName]
        $table.setActiveRow(row)
      },
      async saveRowEvent(row) {
        console.log(row)
        const valid_res = await this.$refs[this.tableRefName].validate([row])
        const error_len = valid_res ? Object.keys(valid_res)?.length : 0
        if (error_len > 0) return
        const $table = this.$refs[this.tableRefName]
        $table.clearEdit().then(() => {
          this.loading = true
          setTimeout(async () => {
            this.loading = false
            const res = await truckRouterRatiosId(row.id, row)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          }, 300)
        })
      },
      cancelRowEvent(row) {
        const $table = this.$refs[this.tableRefName]
        $table.clearEdit().then(() => {
          // 还原行数据
          $table.revertData(row)
        })
      },
      handleClose() {
        this.dialogVisible = false
      },
      handleFold() {
        this.fold = !this.fold
      },
      handleHeight() {
        this.height = this.$baseTableHeight(3) - 30
      },
      handleSizeChange(val) {
        this.form.pageNum = 1
        this.form.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        console.log(val)
        this.form.pageNum = val
        this.queryList()
      },
      pickerClick() {
        this.form.startDate = this.beginTime ? this.beginTime[0] : ''
        this.form.endDate = this.beginTime ? this.beginTime[1] : ''
        this.queryList()
      },
      pickerClickOne() {
        this.form.expiringBeginDate = this.beginTimeOne
          ? this.beginTimeOne[0]
          : ''
        this.form.expiringEndDate = this.beginTimeOne
          ? this.beginTimeOne[1]
          : ''
        this.queryList()
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleBlur() {
        this.form.pageNum = 1
        this.queryList()
      },
      selectAllEvent({ records }) {
        this.bulkOperations.idList = records.map(
          (item, index, array) => item.id
        )
      },
      handleSelectionChange(val) {
        this.bulkOperations.idList = val.map((item, index, array) => item.id)
      },
      rowStyle({ row }) {
        var arr = this.bulkOperations.idList
        for (let i = 0; i < arr.length; i++) {
          if (row.id === arr[i]) {
            return 'rowStyle'
          }
        }
      },
      handleRowClick(row, column) {
        if (column && column.label == '操作') {
          return
        }
        this.$refs.tableSort.toggleRowSelection(row)
      },

      async baseyCode() {
        // const res = await baseTemplateImportGetFileByCode({
        //   code: 'TrailCarCostImpot',
        // })
        window.open(
          `${process.env.VUE_APP_DOWN}线路比例和加价表导入模版.xlsx`
        )
      },
      publishClick() {
        this.$baseConfirm(
          `确认发布当前查询到的全部数据吗？`,
          '提示',
          async () => {
            const res = await truckRouterRatiosPublishBatch(
              this.bulkOperations.idList
            )
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          }
        )
      },
      loseClick() {
        this.$baseConfirm(
          `确认失效当前查询到的全部数据吗？`,
          '提示',
          async () => {
            const res = await truckRouterRatiosLoseBatch(
              this.bulkOperations.idList
            )
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          }
        )
      },
      deleteClick() {
        this.$baseConfirm(
          `确认删除当前查询到的全部数据吗？`,
          '提示',
          async () => {
            const res = await truckRouterRatiosBatch(this.bulkOperations.idList)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          }
        )
      },
      importExectUrl() {
        let val = truckRouterRatiosImportUrl()
        return val
      },
      async importExect(param) {
        var file = param.file
        //发送请求的参数格式为FormData
        const formData = new FormData()
        formData.append('file', file)
        truckRouterRatiosImport(formData)
        this.loading = true
        this.timer = setInterval(() => {
          this.loading = false
          clearInterval(this.timer)
          this.errorLink()
        }, 2000)
        // console.log(res)
        // if (res.code == 200) {
        //   this.$message({
        //     message: res.data.info,
        //     type: 'warning',
        //   })
        // this.haveFail = res.data.haveFail
        // this.queryList()
        // }
      },
      //导出
      async handExport() {
        this.anLoading = true
        this.$set(this.form, 'idList', this.bulkOperations.idList)
        // this.$baseConfirm('确定导出吗', '提示', async () => {
        const res = await trailCarServiceExport(this.form)
        const link = document.createElement('a')
        link.href = res.data.url
        link.click()
        this.anLoading = false
        this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
        // })
      },
      errorLink() {
        this.dialogVisible = false
        this.$router.push({
          name: 'ExportLog',
          params: {
            type: 'upload/TruckRouteRatios',
          },
        })
      },
      async queryList() {
        this.bulkOperations.idList = []
        this.listLoading = true
        const res = await truckRouteRatiosList(this.form)
        this.total = res.data.totalSize
        this.list = res.data.pageList
        this.listLoading = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__body {
      text-align: center;
    }
  }
  ::v-deep .rowStyle {
    background-color: #e8f7fd !important;
    // color: #1890ff;
  }
</style>
