<template>
  <div>
    <vab-query-form v-permissions="{ role: ['管理员', '产品主管'] }">
      <!-- <vab-query-form-top-panel>
        <el-form ref="form" :inline="true" :model="form" @submit.native.prevent>
          <el-form-item label="方数(CBM)">
            <el-input
              v-model="form.cubicMeters"
              clearable
              placeholder="请输入方数"
              @blur="handleBlur"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"

            />
          </el-form-item>
          <el-form-item label="重量" label-width="50px">
            <el-input
              v-model="form.weightKg"
              clearable
              placeholder="请输入重量"
              @blur="handleBlur"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
            />
          </el-form-item>
          <el-form-item label="车型" label-width="50px">
            <el-input
              v-model="form.truckModel"
              clearable
              placeholder="请输入车型"
              @blur="handleBlur"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
            />
          </el-form-item>
          <el-form-item label="状态" label-width="50px">
            <el-select
              v-model="form.status"
              clearable
              filterable
              placeholder="请选择"
              @change="handleBlur"
            >
              <el-option
                v-for="item in optionListTwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel> -->
      <vab-query-form-left-panel :span="20">
        <el-dialog
          :before-close="handleClose"
          title="导入"
          :visible.sync="dialogVisible"
          width="500px"
        >
          <span>
            <el-upload
              v-loading="loading"
              :action="importExectUrl()"
              class="upload-demo"
              drag
              :http-request="importExect"
              :show-file-list="false"
              with-credentials
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </el-upload>
          </span>

          <span slot="footer" class="dialog-footer">
            <!-- <el-button v-show="haveFail" @click="errorLink()">
              导出错误跳转查看
            </el-button> -->
            <el-button @click="dialogVisible = false">取 消</el-button>
          </span>
        </el-dialog>
        <el-button
          icon="el-icon-download"
          size="mini"
          type="primary"
          @click="baseyCode()"
        >
          下载模板
        </el-button>
        <el-button
          icon="el-icon-upload"
          size="mini"
          type="primary"
          @click="dialogVisible = true"
        >
          导入
        </el-button>
        <el-button
          :disabled="!bulkOperations.idList.length > 0 && !isQueryAll"
          icon="el-icon-circle-check"
          size="mini"
          type="primary"
          @click="publishClick()"
        >
          发布
        </el-button>
        <el-button
          :disabled="!bulkOperations.idList.length > 0 && !isQueryAll"
          size="mini"
          type="primary"
          @click="loseClick()"
        >
          <vab-icon icon="forbid-2-line" />
          失效
        </el-button>
        <el-button
          :disabled="!bulkOperations.idList.length > 0 && !isQueryAll"
          icon="el-icon-delete"
          size="mini"
          type="danger"
          @click="deleteClick('', 'delete')"
        >
          删除
        </el-button>
      </vab-query-form-left-panel>
      <!-- <vab-query-form-right-panel :span="4">
        <el-checkbox v-model="isQueryAll" label="操作查询到的全部数据" />
      </vab-query-form-right-panel> -->
    </vab-query-form>
    <div style="height: calc(100vh - 280px)">
      <vxe-table
        :ref="`${tableRefName}`"
        v-loading="listLoading"
        border
        :checkbox-config="{ trigger: 'row', range: true }"
        :data="list"
        :edit-config="{
          trigger: 'manual',
          mode: 'row',
          showIcon: false,
          autoClear: false,
        }"
        :edit-rules="validRules"
        height="100%"
        :keep-source="true"
        :resizable="true"
        :row-class-name="rowStyle"
        :row-config="{
          isHover: true,
          isCurrent: true,
          height: 40,
        }"
        :scroll-y="{ enabled: true }"
        show-overflow
        @checkbox-all="selectAllEvent"
        @checkbox-change="selectAllEvent"
        @row-click="handleRowClick"
      >
        <vxe-column align="center" fixed="left" type="checkbox" width="55" />
        <vxe-column
          align="center"
          :edit-render="{}"
          field="cubicMeters"
          min-width="120"
          title="方数(CBM)"
        >
          <template #edit="{ row }">
            <vxe-input
              v-model.trim="row.cubicMeters"
              clearable
              maxlength="12"
              placeholder="方数(CBM)"
              type="float"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="weightKg"
          min-width="120"
          title="重量(KG)"
        >
          <template #edit="{ row }">
            <!-- <vxe-input v-model="row.weightKg" type="text" /> -->
            <vxe-input
              v-model.trim="row.weightKg"
              clearable
              maxlength="12"
              placeholder="重量(KG)"
              type="float"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="truckModel"
          min-width="100"
          title="车型"
        >
          <template #edit="{ row }">
            <el-select v-model="row.truckModel" filterable placeholder="请选择">
              <el-option
                v-for="item in opereteAllList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="basePrice"
          min-width="150"
          title="起步价（元/趟）"
        >
          <template #edit="{ row }">
            <!-- <vxe-input v-model="row.basePrice" type="text" /> -->
            <!-- <el-input
              v-model.trim="row.basePrice"
              clearable
              maxlength="12"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              placeholder="起步价（元/趟）"
              style="width: 130px"
              @blur="row.basePrice = $event.target.value"
            /> -->
            <vxe-input
              v-model.trim="row.basePrice"
              clearable
              maxlength="12"
              placeholder="起步价（元/趟）"
              type="float"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="price50Cbm"
          min-width="80"
          title="50"
        >
          <template #edit="{ row }">
            <!-- <vxe-input v-model="row.price50Cbm" type="text" /> -->
            <!-- <el-input
              v-model.trim="row.price50Cbm"
              clearable
              maxlength="12"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              placeholder="50"
              style="width: 130px"
              @blur="row.price50Cbm = $event.target.value"
            /> -->
            <vxe-input
              v-model.trim="row.price50Cbm"
              clearable
              maxlength="12"
              placeholder="50"
              type="float"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="price100Cbm"
          min-width="80"
          title="100"
        >
          <template #edit="{ row }">
            <!-- <vxe-input v-model="row.price100Cbm" type="text" /> -->
            <!-- <el-input
              v-model.trim="row.price100Cbm"
              clearable
              maxlength="12"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              placeholder="100"
              style="width: 130px"
              @blur="row.price100Cbm = $event.target.value"
            /> -->
            <vxe-input
              v-model.trim="row.price100Cbm"
              clearable
              maxlength="12"
              placeholder="100"
              type="float"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="price200Cbm"
          min-width="80"
          title="200"
        >
          <template #edit="{ row }">
            <!-- <vxe-input v-model="row.price200Cbm" type="text" /> -->
            <!-- <el-input
              v-model.trim="row.price200Cbm"
              clearable
              maxlength="12"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              placeholder="200"
              style="width: 130px"
              @blur="row.price200Cbm = $event.target.value"
            /> -->
            <vxe-input
              v-model.trim="row.price200Cbm"
              clearable
              maxlength="12"
              placeholder="200"
              type="float"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="price300Cbm"
          min-width="80"
          title="300"
        >
          <template #edit="{ row }">
            <!-- <vxe-input v-model="row.price300Cbm" type="text" /> -->
            <!-- <el-input
              v-model.trim="row.price300Cbm"
              clearable
              maxlength="12"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              placeholder="300"
              style="width: 130px"
              @blur="row.price300Cbm = $event.target.value"
            /> -->
            <vxe-input
              v-model.trim="row.price300Cbm"
              clearable
              maxlength="12"
              placeholder="300"
              type="float"
            />
          </template>
        </vxe-column>

        <vxe-column
          align="center"
          :edit-render="{}"
          field="price600Cbm"
          min-width="80"
          title="600"
        >
          <template #edit="{ row }">
            <!-- <vxe-input v-model="row.price600Cbm" type="text" /> -->
            <!-- <el-input
              v-model.trim="row.price600Cbm"
              clearable
              maxlength="12"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              placeholder="600"
              style="width: 130px"
              @blur="row.price600Cbm = $event.target.value"
            /> -->
            <vxe-input
              v-model.trim="row.price600Cbm"
              clearable
              maxlength="12"
              placeholder="600"
              type="float"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="priceAbove600Cbm"
          min-width="120"
          title="大于600"
        >
          <template #edit="{ row }">
            <!-- <vxe-input v-model="row.priceAbove600Cbm" type="text" /> -->
            <!-- <el-input
              v-model.trim="row.priceAbove600Cbm"
              clearable
              maxlength="12"
              oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,2})?.*$/,'$1')"
              placeholder="大于600"
              style="width: 130px"
              @blur="row.priceAbove600Cbm = $event.target.value"
            /> -->
            <vxe-input
              v-model.trim="row.priceAbove600Cbm"
              clearable
              maxlength="12"
              placeholder="大于600"
              type="float"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="poolingFixedRatio"
          min-width="120"
          title="拼车固定比例"
        >
        <template slot-scope="scope">
            {{ scope.row.poolingFixedRatio | percentage }}
          </template>
          <template #edit="{ row }">
            <vxe-input
              v-model.trim="row.poolingFixedRatio"
              clearable
              maxlength="12"
              placeholder="拼车固定比例"
              min="0"
              max="99999.99"
              type="float"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          :edit-render="{}"
          field="poolingFixedSurcharge"
          min-width="120"
          title="拼车固定加成"
        >
          <template #edit="{ row }">
            <vxe-input
              v-model.trim="row.poolingFixedSurcharge"
              clearable
              maxlength="12"
              placeholder="拼车固定加成"
              min="0"
              max="99999.99"
              type="float"
            />
          </template>
        </vxe-column>
        <vxe-column
          align="center"
          field="createBy"
          min-width="80"
          title="创建人"
        />
        <vxe-column
          align="center"
          field="createTime"
          title="创建时间"
          min-width="120"
        />
        <vxe-column
          align="center"
          field="updateBy"
          min-width="80"
          title="修改人"
        />
        <vxe-column
          align="center"
          field="updateTime"
          min-width="120"
          title="修改时间"
        />
        <vxe-column
          align="center"
          :edit-render="{}"
          field="status"
          min-width="100"
          title="状态"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == '0'">
              <el-tag type="info">失效</el-tag>
            </span>
            <span v-if="scope.row.status == '1'">
              <el-tag>待发布</el-tag>
            </span>
            <span v-if="scope.row.status == '2'">
              <el-tag type="danger">已发布</el-tag>
            </span>
          </template>
          <template #edit="{ row }">
            <el-select v-model="row.status" filterable placeholder="请选择">
              <el-option
                v-for="item in optionListTwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </template>
        </vxe-column>
        <vxe-column
          v-if="hasAccess(['管理员', '产品主管'])"
          align="center"
          fixed="right"
          title="操作"
          width="160"
        >
          <template #default="{ row }">
            <template v-if="$refs[tableRefName].isActiveByRow(row)">
              <vxe-button @click="saveRowEvent(row)">保存</vxe-button>
              <vxe-button @click="cancelRowEvent(row)">取消</vxe-button>
            </template>
            <template v-else>
              <vxe-button @click="editRowEvent(row)">编辑</vxe-button>
            </template>
          </template>
        </vxe-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </vxe-table>
    </div>
    <el-pagination
      background
      :current-page="form.pageNum"
      :layout="layout"
      :page-size="form.pageSize"
      :page-sizes="[10, 50, 100, 500, 1000]"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  import {
  truckBaseFareRulesBatch,
  truckBaseFareRulesBatchId,
  truckBaseFareRulesImport,
  truckBaseFareRulesImportUrl,
  truckBaseFareRulesList,
  truckBaseFareRulesLoseBatch,
  truckBaseFareRulesPublishBatch,
} from '@/api/wholeVehicleProducts';
import { hasAccess } from '@/utils/hasAccess';
  export default {
    filters: {
      percentage(value) {
        return (value * 100).toFixed() + '%'
      },
    },
    props: {
      optionList: Object,
    },
    data() {
      return {
        tableRefName: 'tableSort',
        anLoading: false,
        fold: true,
        listLoading: false,
        height: this.$baseTableHeight(2) - 50,
        list: [],
        form: {
          // cubicMeters: '',
          // weightKg: '',
          // truckModel: '',
          // status: '',
          sort: 'create_time',
          asc: 'false',
          pageNum: 1,
          pageSize: 100,
        },
        isQueryAll: false,
        opereteAllList: [4.2, 6.8, 7.6, 9.6, 13, 17.5],
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        beginTime: [],
        pickerOptions: {},
        beginTimeOne: [],
        pickerOptionsOne: {},
        validRules: {
          cubicMeters: [
            { required: true, message: '请输入方数(CBM)' },
            { trigger: 'change' },
          ],
          weightKg: [
            { required: true, message: '请输入重量(KG)' },
            { trigger: 'change' },
          ],
          basePrice: [
            { required: true, message: '请输入起步价（元/趟）' },
            { trigger: 'change' },
          ],
          price50Cbm: [
            { required: true, message: '请输入50' },
            { trigger: 'change' },
          ],
          price100Cbm: [
            { required: true, message: '请输入100' },
            { trigger: 'change' },
          ],
          price200Cbm: [
            { required: true, message: '请输入200' },
            { trigger: 'change' },
          ],
          price300Cbm: [
            { required: true, message: '请输入300' },
            { trigger: 'change' },
          ],
          price600Cbm: [
            { required: true, message: '请输入600' },
            { trigger: 'change' },
          ],
          priceAbove600Cbm: [
            { required: true, message: '请输入大于600' },
            { trigger: 'change' },
          ],
          poolingFixedRatio: [
            { required: true, message: '请输拼车固定比例' },
            { trigger: 'change' },
          ],
          poolingFixedSurcharge: [
            { required: true, message: '请输入拼车固定加成' },
            { trigger: 'change' },
          ],
        },
        optionListTwo: [
          {
            id: 0,
            name: '失效',
          },
          {
            id: 1,
            name: '待发布',
          },
          {
            id: 2,
            name: '已发布',
          },
        ],
        bulkOperations: {
          type: '',
          idList: [],
        },
        dialogVisible: false,
        haveFail: false,
        loading: false,
      }
    },
    mounted() {},

    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    beforeCloseEdit({ row, column, rowIndex, columnIndex, editing }) {
      // 返回false以阻止关闭编辑模式
      return false
    },
    methods: {
      hasAccess,
      editRowEvent(row) {
        const $table = this.$refs[this.tableRefName]
        $table.setActiveRow(row)
      },
      async saveRowEvent(row) {
        const valid_res = await this.$refs[this.tableRefName].validate([row])
        const error_len = valid_res ? Object.keys(valid_res)?.length : 0
        if (error_len > 0) return
        const $table = this.$refs[this.tableRefName]
        $table.clearEdit().then(() => {
          this.loading = true
          setTimeout(async () => {
            this.loading = false
            await truckBaseFareRulesBatchId(row.id, row)
              .then((res) => {
                this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
                this.queryList()
              })
              .catch((err) => {
                console.log(err)
                this.queryList()
              })
          }, 300)
        })
      },
      cancelRowEvent(row) {
        const $table = this.$refs[this.tableRefName]
        $table.clearEdit().then(() => {
          // 还原行数据
          $table.revertData(row)
        })
      },
      handleFold() {
        this.fold = !this.fold
      },
      handleHeight() {
        this.height = this.$baseTableHeight(3) - 30
      },
      handleSizeChange(val) {
        this.form.pageNum = 1
        this.form.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.form.pageNum = val
        this.queryList()
      },
      pickerClick() {
        this.form.startDate = this.beginTime ? this.beginTime[0] : ''
        this.form.endDate = this.beginTime ? this.beginTime[1] : ''
        this.queryList()
      },
      pickerClickOne() {
        this.form.expiringBeginDate = this.beginTimeOne
          ? this.beginTimeOne[0]
          : ''
        this.form.expiringEndDate = this.beginTimeOne
          ? this.beginTimeOne[1]
          : ''
        this.queryList()
      },
      //导入
      importExectUrl() {
        let val = truckBaseFareRulesImportUrl()
        return val
      },
      async importExect(param) {
        var file = param.file
        //发送请求的参数格式为FormData
        const formData = new FormData()
        formData.append('file', file)
        truckBaseFareRulesImport(formData)
        this.loading = true
        this.timer = setInterval(() => {
          this.loading = false
          clearInterval(this.timer)
          this.errorLink()
        }, 2000)
        // console.log(res)
        // if (res.code == 200) {
        //   this.$message({
        //     message: res.data.info,
        //     type: 'warning',
        //   })
        // this.haveFail = res.data.haveFail
        // this.queryList()
        // }
      },
      handleBlur() {
        this.form.pageNum = 1
        this.queryList()
      },
      async queryList() {
        this.bulkOperations.idList = []
        this.listLoading = true
        await truckBaseFareRulesList(this.form)
          .then((res) => {
            this.total = res.data.totalSize
            this.list = res.data.pageList
            this.listLoading = false
          })
          .catch((err) => {
            this.listLoading = false
          })
      },
      selectAllEvent({ records }) {
        this.bulkOperations.idList = records.map(
          (item, index, array) => item.id
        )
      },
      // handleSelectionChange(val) {
      //   this.bulkOperations.idList = val.map((item, index, array) => item.id)
      // },
      rowStyle({ row }) {
        var arr = this.bulkOperations.idList
        for (let i = 0; i < arr.length; i++) {
          if (row.id === arr[i]) {
            return 'rowStyle'
          }
        }
      },
      handleRowClick(row, column) {
        if (column && column.label == '操作') {
          return
        }
        this.$refs[this.tableRefName].toggleRowSelection(row)
      },
      publishClick() {
        this.$baseConfirm(
          `确认发布当前查询到的全部数据吗？`,
          '提示',
          async () => {
            const res = await truckBaseFareRulesPublishBatch(
              this.bulkOperations.idList
            )
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          }
        )
      },
      loseClick() {
        this.$baseConfirm(
          `确认失效当前查询到的全部数据吗？`,
          '提示',
          async () => {
            const res = await truckBaseFareRulesLoseBatch(
              this.bulkOperations.idList
            )
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          }
        )
      },
      deleteClick() {
        this.$baseConfirm(
          `确认删除当前查询到的全部数据吗？`,
          '提示',
          async () => {
            const res = await truckBaseFareRulesBatch(
              this.bulkOperations.idList
            )
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          }
        )
      },
      // 模板下载
      async baseyCode() {
        // const res = await baseTemplateImportGetFileByCode({
        //   code: 'TrailCarCostImpot',
        // })
        window.open(
          `${process.env.VUE_APP_DOWN}基础计价规则导入模版.xlsx`
        )
      },
      handleClose() {
        this.dialogVisible = false
      },
      errorLink() {
        this.dialogVisible = false
        this.$router.push({
          name: 'ExportLog',
          params: {
            type: 'upload/TruckBaseFareRules',
          },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-dialog__body {
      text-align: center;
    }
  }
  ::v-deep .rowStyle {
    background-color: #e8f7fd !important;
    // color: #1890ff;
  }
</style>
