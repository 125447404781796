var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vab-query-form",
        [
          _c(
            "vab-query-form-top-panel",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    inline: true,
                    "label-width": "70px",
                    model: _vm.form
                  },
                  nativeOn: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "起运市" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择"
                          },
                          on: { change: _vm.handleBlur },
                          model: {
                            value: _vm.form.originCityId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "originCityId", $$v)
                            },
                            expression: "form.originCityId"
                          }
                        },
                        _vm._l(_vm.optionList.cityGetValidList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "目的市" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "180px" },
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择"
                          },
                          on: { change: _vm.handleBlur },
                          model: {
                            value: _vm.form.destinationCityId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "destinationCityId", $$v)
                            },
                            expression: "form.destinationCityId"
                          }
                        },
                        _vm._l(_vm.optionList.cityGetValidList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", "label-width": "50px" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            filterable: "",
                            placeholder: "请选择"
                          },
                          on: { change: _vm.handleBlur },
                          model: {
                            value: _vm.form.status,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status"
                          }
                        },
                        _vm._l(_vm.optionListTwo, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "vab-query-form-left-panel",
            {
              directives: [
                {
                  name: "permissions",
                  rawName: "v-permissions",
                  value: { role: ["管理员", "产品主管"] },
                  expression: "{ role: ['管理员', '产品主管'] }"
                }
              ],
              attrs: { span: 20 }
            },
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    "before-close": _vm.handleClose,
                    title: "导入",
                    visible: _vm.dialogVisible,
                    width: "500px"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _c(
                        "el-upload",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading"
                            }
                          ],
                          staticClass: "upload-demo",
                          attrs: {
                            action: _vm.importExectUrl(),
                            drag: "",
                            "http-request": _vm.importExect,
                            "show-file-list": false,
                            "with-credentials": ""
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v(" 将文件拖到此处，或 "),
                            _c("em", [_vm._v("点击上传")])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-download",
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.baseyCode("", "add")
                    }
                  }
                },
                [_vm._v(" 下载模板 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-upload",
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = true
                    }
                  }
                },
                [_vm._v(" 导入 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled:
                      !_vm.bulkOperations.idList.length > 0 && !_vm.isQueryAll,
                    icon: "el-icon-circle-check",
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.publishClick()
                    }
                  }
                },
                [_vm._v(" 发布 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled:
                      !_vm.bulkOperations.idList.length > 0 && !_vm.isQueryAll,
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.loseClick()
                    }
                  }
                },
                [
                  _c("vab-icon", { attrs: { icon: "forbid-2-line" } }),
                  _vm._v(" 失效 ")
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled:
                      !_vm.bulkOperations.idList.length > 0 && !_vm.isQueryAll,
                    icon: "el-icon-delete",
                    size: "mini",
                    type: "danger"
                  },
                  on: {
                    click: function($event) {
                      return _vm.deleteClick()
                    }
                  }
                },
                [_vm._v(" 删除 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 330px)" } },
        [
          _c(
            "vxe-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "" + _vm.tableRefName,
              attrs: {
                border: "",
                "checkbox-config": { trigger: "row", range: true },
                data: _vm.list,
                "edit-config": {
                  trigger: "manual",
                  mode: "row",
                  showIcon: false,
                  autoClear: false
                },
                "edit-rules": _vm.validRules,
                height: "100%",
                "keep-source": true,
                resizable: true,
                "row-class-name": _vm.rowStyle,
                "row-config": { isHover: true, isCurrent: true, height: 40 },
                "scroll-y": { enabled: true },
                "show-overflow": ""
              },
              on: {
                "checkbox-all": _vm.selectAllEvent,
                "checkbox-change": _vm.selectAllEvent,
                "row-click": _vm.handleRowClick
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png")
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  fixed: "left",
                  type: "checkbox",
                  width: "55"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  "min-width": "150",
                  title: "起运市"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(scope.row.originCity) + " ")
                        ])
                      ]
                    }
                  },
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "vxe-select",
                          {
                            attrs: { filterable: "", placeholder: "请选择" },
                            on: {
                              change: function($event) {
                                return _vm.selectClick(row)
                              }
                            },
                            model: {
                              value: row.originCityId,
                              callback: function($$v) {
                                _vm.$set(row, "originCityId", $$v)
                              },
                              expression: "row.originCityId"
                            }
                          },
                          _vm._l(_vm.optionList.cityGetValidList, function(
                            item
                          ) {
                            return _c("vxe-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  "min-width": "150",
                  title: "目的市"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("span", [
                          _vm._v(" " + _vm._s(scope.row.destinationCity) + " ")
                        ])
                      ]
                    }
                  },
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "vxe-select",
                          {
                            attrs: { filterable: "", placeholder: "请选择" },
                            on: {
                              change: function($event) {
                                return _vm.selectClickTwo(row)
                              }
                            },
                            model: {
                              value: row.destinationCityId,
                              callback: function($$v) {
                                _vm.$set(row, "destinationCityId", $$v)
                              },
                              expression: "row.destinationCityId"
                            }
                          },
                          _vm._l(_vm.optionList.cityGetValidList, function(
                            item
                          ) {
                            return _c("vxe-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "vxe-colgroup",
                { attrs: { align: "center", title: "线路比例" } },
                [
                  _c("vxe-column", {
                    attrs: {
                      align: "center",
                      "edit-render": {},
                      field: "truck42Ratio",
                      "min-width": "100",
                      title: "4.2"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "edit",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("vxe-input", {
                              attrs: {
                                clearable: "",
                                maxlength: "12",
                                placeholder: "4.2",
                                type: "float"
                              },
                              model: {
                                value: row.truck42Ratio,
                                callback: function($$v) {
                                  _vm.$set(
                                    row,
                                    "truck42Ratio",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "row.truck42Ratio"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("vxe-column", {
                    attrs: {
                      align: "center",
                      "edit-render": {},
                      field: "truck68Ratio",
                      "min-width": "100",
                      title: "6.8"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "edit",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("vxe-input", {
                              attrs: {
                                clearable: "",
                                maxlength: "12",
                                placeholder: "6.8",
                                type: "float"
                              },
                              model: {
                                value: row.truck68Ratio,
                                callback: function($$v) {
                                  _vm.$set(
                                    row,
                                    "truck68Ratio",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "row.truck68Ratio"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("vxe-column", {
                    attrs: {
                      align: "center",
                      "edit-render": {},
                      field: "truck76Ratio",
                      "min-width": "100",
                      title: "7.6"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "edit",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("vxe-input", {
                              attrs: {
                                clearable: "",
                                maxlength: "12",
                                placeholder: "7.6",
                                type: "float"
                              },
                              model: {
                                value: row.truck76Ratio,
                                callback: function($$v) {
                                  _vm.$set(
                                    row,
                                    "truck76Ratio",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "row.truck76Ratio"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("vxe-column", {
                    attrs: {
                      align: "center",
                      "edit-render": {},
                      field: "truck96Ratio",
                      "min-width": "100",
                      title: "9.6"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "edit",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("vxe-input", {
                              attrs: {
                                clearable: "",
                                maxlength: "12",
                                placeholder: "9.6",
                                type: "float"
                              },
                              model: {
                                value: row.truck96Ratio,
                                callback: function($$v) {
                                  _vm.$set(
                                    row,
                                    "truck96Ratio",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "row.truck96Ratio"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("vxe-column", {
                    attrs: {
                      align: "center",
                      "edit-render": {},
                      field: "truck13Ratio",
                      "min-width": "100",
                      title: "13"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "edit",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("vxe-input", {
                              attrs: {
                                clearable: "",
                                maxlength: "12",
                                placeholder: "13",
                                type: "float"
                              },
                              model: {
                                value: row.truck13Ratio,
                                callback: function($$v) {
                                  _vm.$set(
                                    row,
                                    "truck13Ratio",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "row.truck13Ratio"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("vxe-column", {
                    attrs: {
                      align: "center",
                      "edit-render": {},
                      field: "truck175Ratio",
                      "min-width": "100",
                      title: "17.5"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "edit",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("vxe-input", {
                              attrs: {
                                clearable: "",
                                maxlength: "12",
                                placeholder: "17.5",
                                type: "float"
                              },
                              model: {
                                value: row.truck175Ratio,
                                callback: function($$v) {
                                  _vm.$set(
                                    row,
                                    "truck175Ratio",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "row.truck175Ratio"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "minSurcharge",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                  title: "整车最低加价"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "整车最低加价",
                            type: "float",
                            min: "0",
                            max: "99999.99"
                          },
                          model: {
                            value: row.minSurcharge,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "minSurcharge",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.minSurcharge"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "maxSurcharge",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                  title: "整车最高加价"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "整车最高加价",
                            type: "float",
                            min: "0",
                            max: "99999.99"
                          },
                          model: {
                            value: row.maxSurcharge,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "maxSurcharge",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.maxSurcharge"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "surchargeRatio",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                  title: "整车加价比例"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("percentage")(scope.row.surchargeRatio)
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "整车加价比例",
                            type: "float",
                            min: "0",
                            max: "99999.99"
                          },
                          model: {
                            value: row.surchargeRatio,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "surchargeRatio",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.surchargeRatio"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "poolingMinSurcharge",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                  title: "拼车最低加价"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "拼车最低加价",
                            type: "float",
                            min: "0",
                            max: "99999.99"
                          },
                          model: {
                            value: row.poolingMinSurcharge,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "poolingMinSurcharge",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.poolingMinSurcharge"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "poolingMaxSurcharge",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                  title: "拼车最高加价"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "拼车最高加价",
                            type: "float",
                            min: "0",
                            max: "99999.99"
                          },
                          model: {
                            value: row.poolingMaxSurcharge,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "poolingMaxSurcharge",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.poolingMaxSurcharge"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "poolingSurchargeRatio",
                  "min-width": "120",
                  "show-overflow-tooltip": "",
                  title: "拼车加价比例"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("percentage")(
                                scope.row.poolingSurchargeRatio
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "拼车加价比例",
                            type: "float"
                          },
                          model: {
                            value: row.poolingSurchargeRatio,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "poolingSurchargeRatio",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.poolingSurchargeRatio"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "effectiveDate",
                  "min-width": "120",
                  title: "生效日期"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "data-format": "yyyy/MM/dd",
                            format: "yyyy/MM/dd",
                            placeholder: "请选择",
                            type: "datetime",
                            "value-format": "yyyy/MM/dd"
                          },
                          model: {
                            value: row.effectiveDate,
                            callback: function($$v) {
                              _vm.$set(row, "effectiveDate", $$v)
                            },
                            expression: "row.effectiveDate"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "expiryDate",
                  "min-width": "120",
                  title: "失效日期"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("el-date-picker", {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "data-format": "yyyy/MM/dd",
                            format: "yyyy/MM/dd",
                            placeholder: "请选择",
                            type: "datetime",
                            "value-format": "yyyy/MM/dd"
                          },
                          model: {
                            value: row.expiryDate,
                            callback: function($$v) {
                              _vm.$set(row, "expiryDate", $$v)
                            },
                            expression: "row.expiryDate"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "status",
                  "min-width": "100",
                  title: "状态"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status == "0"
                          ? _c(
                              "span",
                              [
                                _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("失效")
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.status == "1"
                          ? _c("span", [_c("el-tag", [_vm._v("待发布")])], 1)
                          : _vm._e(),
                        scope.row.status == "2"
                          ? _c(
                              "span",
                              [
                                _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("已发布")
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "", placeholder: "请选择" },
                            model: {
                              value: row.status,
                              callback: function($$v) {
                                _vm.$set(row, "status", $$v)
                              },
                              expression: "row.status"
                            }
                          },
                          _vm._l(_vm.optionListTwo, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "remark",
                  "min-width": "120",
                  title: "备注"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: { clearable: "", placeholder: "备注" },
                          model: {
                            value: row.remark,
                            callback: function($$v) {
                              _vm.$set(row, "remark", $$v)
                            },
                            expression: "row.remark"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "createBy",
                  "min-width": "80",
                  title: "创建人"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "createTime",
                  "min-width": "120",
                  title: "创建时间"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "updateBy",
                  "min-width": "80",
                  title: "修改人"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "updateTime",
                  "min-width": "120",
                  title: "修改时间"
                }
              }),
              _vm.hasAccess(["管理员", "产品主管"])
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      fixed: "right",
                      title: "操作",
                      width: "160"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.$refs[_vm.tableRefName].isActiveByRow(row)
                                ? [
                                    _c(
                                      "vxe-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.saveRowEvent(row)
                                          }
                                        }
                                      },
                                      [_vm._v("保存")]
                                    ),
                                    _c(
                                      "vxe-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.cancelRowEvent(row)
                                          }
                                        }
                                      },
                                      [_vm._v("取消")]
                                    )
                                  ]
                                : [
                                    _c(
                                      "vxe-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.editRowEvent(row)
                                          }
                                        }
                                      },
                                      [_vm._v("编辑")]
                                    )
                                  ]
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      578517629
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.form.pageNum,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          "page-sizes": [10, 50, 100, 500, 1000],
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }