var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vab-query-form",
        {
          directives: [
            {
              name: "permissions",
              rawName: "v-permissions",
              value: { role: ["管理员", "产品主管"] },
              expression: "{ role: ['管理员', '产品主管'] }"
            }
          ]
        },
        [
          _c(
            "vab-query-form-left-panel",
            { attrs: { span: 20 } },
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    "before-close": _vm.handleClose,
                    title: "导入",
                    visible: _vm.dialogVisible,
                    width: "500px"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisible = $event
                    }
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _c(
                        "el-upload",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading"
                            }
                          ],
                          staticClass: "upload-demo",
                          attrs: {
                            action: _vm.importExectUrl(),
                            drag: "",
                            "http-request": _vm.importExect,
                            "show-file-list": false,
                            "with-credentials": ""
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-upload" }),
                          _c("div", { staticClass: "el-upload__text" }, [
                            _vm._v(" 将文件拖到此处，或 "),
                            _c("em", [_vm._v("点击上传")])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer"
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-download",
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.baseyCode()
                    }
                  }
                },
                [_vm._v(" 下载模板 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    icon: "el-icon-upload",
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = true
                    }
                  }
                },
                [_vm._v(" 导入 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled:
                      !_vm.bulkOperations.idList.length > 0 && !_vm.isQueryAll,
                    icon: "el-icon-circle-check",
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.publishClick()
                    }
                  }
                },
                [_vm._v(" 发布 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled:
                      !_vm.bulkOperations.idList.length > 0 && !_vm.isQueryAll,
                    size: "mini",
                    type: "primary"
                  },
                  on: {
                    click: function($event) {
                      return _vm.loseClick()
                    }
                  }
                },
                [
                  _c("vab-icon", { attrs: { icon: "forbid-2-line" } }),
                  _vm._v(" 失效 ")
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled:
                      !_vm.bulkOperations.idList.length > 0 && !_vm.isQueryAll,
                    icon: "el-icon-delete",
                    size: "mini",
                    type: "danger"
                  },
                  on: {
                    click: function($event) {
                      return _vm.deleteClick("", "delete")
                    }
                  }
                },
                [_vm._v(" 删除 ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { height: "calc(100vh - 280px)" } },
        [
          _c(
            "vxe-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading"
                }
              ],
              ref: "" + _vm.tableRefName,
              attrs: {
                border: "",
                "checkbox-config": { trigger: "row", range: true },
                data: _vm.list,
                "edit-config": {
                  trigger: "manual",
                  mode: "row",
                  showIcon: false,
                  autoClear: false
                },
                "edit-rules": _vm.validRules,
                height: "100%",
                "keep-source": true,
                resizable: true,
                "row-class-name": _vm.rowStyle,
                "row-config": {
                  isHover: true,
                  isCurrent: true,
                  height: 40
                },
                "scroll-y": { enabled: true },
                "show-overflow": ""
              },
              on: {
                "checkbox-all": _vm.selectAllEvent,
                "checkbox-change": _vm.selectAllEvent,
                "row-click": _vm.handleRowClick
              },
              scopedSlots: _vm._u([
                {
                  key: "empty",
                  fn: function() {
                    return [
                      _c("el-image", {
                        staticClass: "vab-data-empty",
                        attrs: {
                          src: require("@/assets/empty_images/data_empty.png")
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  fixed: "left",
                  type: "checkbox",
                  width: "55"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "cubicMeters",
                  "min-width": "120",
                  title: "方数(CBM)"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "方数(CBM)",
                            type: "float"
                          },
                          model: {
                            value: row.cubicMeters,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "cubicMeters",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.cubicMeters"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "weightKg",
                  "min-width": "120",
                  title: "重量(KG)"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "重量(KG)",
                            type: "float"
                          },
                          model: {
                            value: row.weightKg,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "weightKg",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.weightKg"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "truckModel",
                  "min-width": "100",
                  title: "车型"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "", placeholder: "请选择" },
                            model: {
                              value: row.truckModel,
                              callback: function($$v) {
                                _vm.$set(row, "truckModel", $$v)
                              },
                              expression: "row.truckModel"
                            }
                          },
                          _vm._l(_vm.opereteAllList, function(item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item, value: item }
                            })
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "basePrice",
                  "min-width": "150",
                  title: "起步价（元/趟）"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "起步价（元/趟）",
                            type: "float"
                          },
                          model: {
                            value: row.basePrice,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "basePrice",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.basePrice"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "price50Cbm",
                  "min-width": "80",
                  title: "50"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "50",
                            type: "float"
                          },
                          model: {
                            value: row.price50Cbm,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "price50Cbm",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.price50Cbm"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "price100Cbm",
                  "min-width": "80",
                  title: "100"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "100",
                            type: "float"
                          },
                          model: {
                            value: row.price100Cbm,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "price100Cbm",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.price100Cbm"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "price200Cbm",
                  "min-width": "80",
                  title: "200"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "200",
                            type: "float"
                          },
                          model: {
                            value: row.price200Cbm,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "price200Cbm",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.price200Cbm"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "price300Cbm",
                  "min-width": "80",
                  title: "300"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "300",
                            type: "float"
                          },
                          model: {
                            value: row.price300Cbm,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "price300Cbm",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.price300Cbm"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "price600Cbm",
                  "min-width": "80",
                  title: "600"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "600",
                            type: "float"
                          },
                          model: {
                            value: row.price600Cbm,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "price600Cbm",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.price600Cbm"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "priceAbove600Cbm",
                  "min-width": "120",
                  title: "大于600"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "大于600",
                            type: "float"
                          },
                          model: {
                            value: row.priceAbove600Cbm,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "priceAbove600Cbm",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.priceAbove600Cbm"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "poolingFixedRatio",
                  "min-width": "120",
                  title: "拼车固定比例"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("percentage")(scope.row.poolingFixedRatio)
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "拼车固定比例",
                            min: "0",
                            max: "99999.99",
                            type: "float"
                          },
                          model: {
                            value: row.poolingFixedRatio,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "poolingFixedRatio",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.poolingFixedRatio"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "poolingFixedSurcharge",
                  "min-width": "120",
                  title: "拼车固定加成"
                },
                scopedSlots: _vm._u([
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("vxe-input", {
                          attrs: {
                            clearable: "",
                            maxlength: "12",
                            placeholder: "拼车固定加成",
                            min: "0",
                            max: "99999.99",
                            type: "float"
                          },
                          model: {
                            value: row.poolingFixedSurcharge,
                            callback: function($$v) {
                              _vm.$set(
                                row,
                                "poolingFixedSurcharge",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "row.poolingFixedSurcharge"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "createBy",
                  "min-width": "80",
                  title: "创建人"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "createTime",
                  title: "创建时间",
                  "min-width": "120"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "updateBy",
                  "min-width": "80",
                  title: "修改人"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  field: "updateTime",
                  "min-width": "120",
                  title: "修改时间"
                }
              }),
              _c("vxe-column", {
                attrs: {
                  align: "center",
                  "edit-render": {},
                  field: "status",
                  "min-width": "100",
                  title: "状态"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.status == "0"
                          ? _c(
                              "span",
                              [
                                _c("el-tag", { attrs: { type: "info" } }, [
                                  _vm._v("失效")
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        scope.row.status == "1"
                          ? _c("span", [_c("el-tag", [_vm._v("待发布")])], 1)
                          : _vm._e(),
                        scope.row.status == "2"
                          ? _c(
                              "span",
                              [
                                _c("el-tag", { attrs: { type: "danger" } }, [
                                  _vm._v("已发布")
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "edit",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { filterable: "", placeholder: "请选择" },
                            model: {
                              value: row.status,
                              callback: function($$v) {
                                _vm.$set(row, "status", $$v)
                              },
                              expression: "row.status"
                            }
                          },
                          _vm._l(_vm.optionListTwo, function(item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id }
                            })
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm.hasAccess(["管理员", "产品主管"])
                ? _c("vxe-column", {
                    attrs: {
                      align: "center",
                      fixed: "right",
                      title: "操作",
                      width: "160"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(ref) {
                            var row = ref.row
                            return [
                              _vm.$refs[_vm.tableRefName].isActiveByRow(row)
                                ? [
                                    _c(
                                      "vxe-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.saveRowEvent(row)
                                          }
                                        }
                                      },
                                      [_vm._v("保存")]
                                    ),
                                    _c(
                                      "vxe-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.cancelRowEvent(row)
                                          }
                                        }
                                      },
                                      [_vm._v("取消")]
                                    )
                                  ]
                                : [
                                    _c(
                                      "vxe-button",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.editRowEvent(row)
                                          }
                                        }
                                      },
                                      [_vm._v("编辑")]
                                    )
                                  ]
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      578517629
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          background: "",
          "current-page": _vm.form.pageNum,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          "page-sizes": [10, 50, 100, 500, 1000],
          total: _vm.total
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }